import _ from 'underscore';

export const ChangesPendingStore = {
  changePendingForIds: [],
  uploadRequired: false,

  changePending(id) {
    const index = this.changePendingForIds.indexOf(id);
    if (index < 0) {
      this.changePendingForIds.push(id);
    }
  },

  changeSuccessful(id) {
    const index = this.changePendingForIds.indexOf(id);
    if (index > -1) {
      this.changePendingForIds.splice(index, 1);
    }
  },

  areChangesPending(ids) {
    if (ids) {
      const me = this;
      return _.some(ids, (id) => _.contains(me.changePendingForIds, id));
    }
    return false;
  },

  reset() {
    this.changePendingForIds = [];
  },

  setUploadRequired() {
    this.uploadRequired = true;
  },

  isUploadRequired() {
    return this.uploadRequired;
  },

  resetUploadRequired() {
    this.uploadRequired = false;
  },
};
