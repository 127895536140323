export enum ReportType {
  AUDIT_LOGS = 'audit_logs',
  GROUPS = 'groups',
  GUIDES = 'guides',
  CHALLENGES = 'challenges',
  CONTENT_PROVIDER_CONTENT = 'content_provider_content',
  SCORM = 'scorm',
  SERIES = 'series',
  USERS = 'users',
  GUIDE_SUBMISSIONS = 'guide_submissions',
  CHALLENGE_SUBMISSIONS = 'challenge_submissions',
  BADGES = 'badges',
  EVENTS = 'events',
  RESOURCES = 'resources',
  LIBRARIES = 'libraries',
  TRAININGS = 'trainings',
  GUIDE_SUBMISSIONS_NEW = 'guide_submissions_new',
}
