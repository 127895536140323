import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation, QueryClient } from '@tanstack/react-query';
import { useToast } from '@workramp/ui';
import { showLoader, hideLoader, parseApiError } from '~/common';

// Create react-query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
});

export function useDefaultMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { open: openToast } = useToast();

  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    onMutate: () => {
      showLoader(false, true);
      return undefined; // TypeScript doesn't like it when we try to return the `number` from showLoader
    },
    onError: (error) => openToast({ children: parseApiError(error) }),
    onSettled: () => hideLoader(),
    ...options,
  });
}
