import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { managedConsent } from './didomi';

// Configuration: https://docs.datadoghq.com/real_user_monitoring/browser/
// EU: https://app.datadoghq.eu/rum/application/03c5de12-7e35-4a5c-aa61-2773b694bae7/edit?integration=javascript
// US: https://app.datadoghq.com/rum/application/d044ec8f-6fd4-4946-ad55-61fe2761e361/edit?integration=javascript

const { ENV_VARS, getCurrentUser, getExternalUser } = window;

if (!['development', 'test', null].includes(ENV_VARS.DATADOG_ENV)) {
  const service = 'workramp-api';

  let applicationId: string | undefined;
  let clientToken: string | undefined;
  let site: RumInitConfiguration['site'] | undefined; // https://docs.datadoghq.com/getting_started/site/
  if (ENV_VARS.DATADOG_ENV === 'eu') {
    applicationId = '03c5de12-7e35-4a5c-aa61-2773b694bae7';
    clientToken = 'pub9488dcd02dda63b4ea91c8fd2f6ed303';
    site = 'datadoghq.eu';
  } else {
    clientToken = 'pubb10eafd36bab294b107588d56b02e102';
    applicationId = 'd044ec8f-6fd4-4946-ad55-61fe2761e361';
    site = 'datadoghq.com';
  }

  const user =
    (typeof getCurrentUser === 'function' && getCurrentUser()) ||
    (typeof getExternalUser === 'function' && getExternalUser()) ||
    null;

  datadogLogs.init({
    clientToken,
    service,
    env: ENV_VARS.DATADOG_ENV,
    site,
    version: ENV_VARS.DATADOG_VERSION,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  });

  datadogLogs.setGlobalContextProperty('user_id', user?.id);

  managedConsent('c:datadog-892VRMnf', () => {
    datadogLogs.setGlobalContextProperty('user_email', user?.email);

    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      env: ENV_VARS.DATADOG_ENV,
      version: ENV_VARS.DATADOG_VERSION,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      telemetrySampleRate: 0,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setUser({ id: user?.id, email: user?.email });
    datadogRum.setGlobalContextProperty('user_id', user?.id);
    datadogRum.setGlobalContextProperty('user_email', user?.email);
  });
}

export { datadogRum, datadogLogs };
