import type { IconNames } from '@workramp/ui';
import _ from 'underscore';
import { datadogLogs } from '~/lib/datadog';

const { APP_CONSTANTS, I18n } = window;

// Create a mapping to icon names from constants
type AssetPickerTypeValues = (typeof APP_CONSTANTS.ASSET_PICKER_TYPES)[keyof typeof APP_CONSTANTS.ASSET_PICKER_TYPES];
const ICON_TYPE_MAPPING: Partial<Record<AssetPickerTypeValues, IconNames>> = {
  [APP_CONSTANTS.ASSET_PICKER_TYPES.CHALLENGE]: 'challenge:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.CLASS]: 'event:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.GUIDE]: 'guide:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.RESOURCE]: 'resource:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.SCORM]: 'scorm:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.SERIES]: 'path:regular',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.LINKEDIN_LEARNING]: 'linkedin:brand',
  [APP_CONSTANTS.ASSET_PICKER_TYPES.UDEMY]: 'udemy:brand',
};

const AssetPickerTypes = {
  ...APP_CONSTANTS.ASSET_PICKER_TYPES,

  ALL: [
    APP_CONSTANTS.ASSET_PICKER_TYPES.ACADEMY_CERTIFICATION,
    APP_CONSTANTS.ASSET_PICKER_TYPES.ACADEMY_PATH,
    APP_CONSTANTS.ASSET_PICKER_TYPES.ACADEMY_EVENT,
    APP_CONSTANTS.ASSET_PICKER_TYPES.ACADEMY_COLLECTION,
    APP_CONSTANTS.ASSET_PICKER_TYPES.CHALLENGE,
    APP_CONSTANTS.ASSET_PICKER_TYPES.CLASS,
    APP_CONSTANTS.ASSET_PICKER_TYPES.EVENT,
    APP_CONSTANTS.ASSET_PICKER_TYPES.EVENT_TEMPLATE,
    APP_CONSTANTS.ASSET_PICKER_TYPES.FOLDER,
    APP_CONSTANTS.ASSET_PICKER_TYPES.GO1_CONTENT,
    APP_CONSTANTS.ASSET_PICKER_TYPES.GUIDE_SECTION,
    APP_CONSTANTS.ASSET_PICKER_TYPES.GUIDE_TASK,
    APP_CONSTANTS.ASSET_PICKER_TYPES.GUIDE,
    APP_CONSTANTS.ASSET_PICKER_TYPES.LIBRARY,
    APP_CONSTANTS.ASSET_PICKER_TYPES.RESOURCE,
    APP_CONSTANTS.ASSET_PICKER_TYPES.SCORM,
    APP_CONSTANTS.ASSET_PICKER_TYPES.SECTION,
    APP_CONSTANTS.ASSET_PICKER_TYPES.SERIES,
    APP_CONSTANTS.ASSET_PICKER_TYPES.TASK,
    APP_CONSTANTS.ASSET_PICKER_TYPES.UDEMY,
    APP_CONSTANTS.ASSET_PICKER_TYPES.UNIVERSITY,
    APP_CONSTANTS.ASSET_PICKER_TYPES.UNIVERSITY_FOLDER,
    APP_CONSTANTS.ASSET_PICKER_TYPES.WORKFLOW,
  ],

  // @cmorford todo
  // some icons are not supported by legacy component library, which relies on 16x16 icons
  // this can be cleaned up when
  // 1. new component lib adds "brand icons", see ENG-2925 and ENG-2929
  // 2. when theme is available on all components
  specialCaseIcons: [APP_CONSTANTS.ASSET_PICKER_TYPES.UDEMY],

  getIconSrc: (type) => {
    // do not add cases to this function unless they are in specialCaseIcons
    switch (type) {
      case AssetPickerTypes.UDEMY:
        return '/images/udemy_content_icon.svg';
      default:
        datadogLogs.logger.info(`Cannot get icon src for ${type}`);
        return null;
    }
  },

  getIconNameFromType: (type: AssetPickerTypeValues): IconNames | undefined => ICON_TYPE_MAPPING[type],

  getShareLink: (type, shareLink) => {
    let contentType = type;
    let contentTypeInitial = '';
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, contentType)) {
      contentType = 'top_level_content';
    }

    switch (contentType) {
      case AssetPickerTypes.CHALLENGE:
        contentTypeInitial = 'c';
        break;
      case AssetPickerTypes.GUIDE:
        contentTypeInitial = 'g';
        break;
      case AssetPickerTypes.SERIES:
        contentTypeInitial = 'p';
        break;
      case AssetPickerTypes.RESOURCE:
        contentTypeInitial = 'r';
        break;
      case AssetPickerTypes.CLASS:
        contentTypeInitial = 'events';
        break;
      case 'top_level_content':
        contentTypeInitial = 'tlc';
        break;
      default:
        datadogLogs.logger.info(`Cannot get share link for ${contentType}`);
        return null;
    }

    return `${window.location.protocol}//${window.location.hostname}/${contentTypeInitial}/${shareLink}`;
  },

  // TODO: Expand to other asset types (even better, make these ES6 classes instead of functions with switch statements!)
  getCreateItemUrl: (type) => {
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, type)) {
      return '/top_level_content/';
    }

    switch (type) {
      case AssetPickerTypes.CHALLENGE:
        return '/challenges/';
      case AssetPickerTypes.CLASS:
        return '/classes/v2';
      case AssetPickerTypes.FOLDER:
        return '/item_folders/';
      case AssetPickerTypes.GUIDE:
        return '/courses/';
      case AssetPickerTypes.RESOURCE:
        return '/resources/';
      case AssetPickerTypes.SERIES:
        return '/training_series/';
      case AssetPickerTypes.UNIVERSITY:
        return '/universities/';
      case AssetPickerTypes.WORKFLOW:
        return '/training_workflows/';
      default:
        datadogLogs.logger.info(`Cannot get create item url for ${type}`);
        return null;
    }
  },

  // TODO: Expand to other asset types (even better, make these ES6 classes instead of functions with switch statements!)
  getUpdateItemUrl: (item) => {
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, item.type)) {
      return `/top_level_content/${item.id}`;
    }

    switch (item.type) {
      case AssetPickerTypes.CHALLENGE:
        return `/challenges/${item.id}`;
      case AssetPickerTypes.CLASS:
        return `/classes/${item.id}`;
      case AssetPickerTypes.FOLDER:
        return `/item_folders/${item.id}`;
      case AssetPickerTypes.GUIDE:
        return `/courses/${item.id}`;
      case AssetPickerTypes.RESOURCE:
        return `/resources/${item.id}`;
      case AssetPickerTypes.SERIES:
        return `/training_series/${item.id}`;
      case AssetPickerTypes.UNIVERSITY:
        return `/universities/${item.id}`;
      case AssetPickerTypes.UNIVERSITY_FOLDER:
        return `/universities/${item.universityId}/folders/${item.id}`;
      case AssetPickerTypes.WORKFLOW:
        return `/training_workflows/${item.id}`;
      default:
        datadogLogs.logger.info(`Cannot get update item url for ${item.type}`);
        return null;
    }
  },

  getViewItemUrlForAcademyLearnerSearch: ({ resultId, resultType, contentId, contentType, parentType, parentId }) => {
    if (parentType && parentType !== AssetPickerTypes.ACADEMY_EVENT) {
      switch (contentType) {
        case AssetPickerTypes.GUIDE:
          return `/training/${resultId}/overview`;
        case AssetPickerTypes.RESOURCE:
          return `/training/${resultId}/register`;
        case AssetPickerTypes.SCORM:
          return `/scorm/${contentId}`;
        default:
          datadogLogs.logger.info(`Cannot get view item url for ${resultType} with parent ${parentType}`);
          return null;
      }
    } else {
      switch (resultType) {
        case AssetPickerTypes.ACADEMY_EVENT:
          return `/events/${resultId}`;
        case AssetPickerTypes.ACADEMY_CERTIFICATION:
          return `/certifications/${resultId}`;
        case AssetPickerTypes.ACADEMY_COLLECTION:
          return `/collections/${resultId}`;
        case AssetPickerTypes.ACADEMY_PATH:
          return `/paths/${resultId}`;
        case NonAssetPickerTypes.ACADEMY_EVENT_SESSION:
          return `/events/${parentId}`;
        case NonAssetPickerTypes.ACADEMY_EVENT_ON_DEMAND_CONTENT:
          return `/events/${parentId}/on_demand_content/${resultId}`;

        default:
          datadogLogs.logger.info(`Cannot get view item url for ${resultType} with parent ${parentType}`);
          return null;
      }
    }
  },

  getViewItemUrl: (
    item: { type: string; id: string },
    assignmentId: string | null = null,
    forAcademy = false,
  ): string | null => {
    let url;
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, item.type)) {
      url = `/${item.type}/${item.id}`;
      return assignmentId ? `${url}?assignment_id=${assignmentId}` : url;
    }

    switch (item.type) {
      case AssetPickerTypes.ACADEMY_CERTIFICATION:
        return `/certifications/${item.id}`;
      case AssetPickerTypes.ACADEMY_PATH:
        return `/paths/${item.id}`;
      case AssetPickerTypes.EVENT:
      case AssetPickerTypes.ACADEMY_EVENT:
        return `/events/${item.id}`;
      case AssetPickerTypes.ACADEMY_COLLECTION:
        return `/collections/${item.id}`;
      case AssetPickerTypes.CHALLENGE:
        return `/challenges/${item.id}`;
      case AssetPickerTypes.GUIDE:
        return forAcademy ? `/training/${item.id}/overview` : `/guides/${item.id}`;
      case AssetPickerTypes.GUIDE_SECTION:
      case AssetPickerTypes.SECTION:
        return `/guide_sections/${item.id}`;
      case AssetPickerTypes.GUIDE_TASK:
      case AssetPickerTypes.TASK:
        return `/tasks/${item.id}`;
      case AssetPickerTypes.RESOURCE:
        return forAcademy ? `/training/${item.id}/register` : `/resources/${item.id}`;
      case AssetPickerTypes.PATH:
      case AssetPickerTypes.SERIES:
        return `/paths/${item.id}`;
      case AssetPickerTypes.UNIVERSITY:
      case AssetPickerTypes.LIBRARY:
        return `/universities/${item.id}`;
      default:
        datadogLogs.logger.info(`Cannot get view item url for ${item.type}`);
        return null;
    }
  },

  /** Admin viewing page. May be null if item has no viewing page. */
  getItemAdminUrl: (
    item: {
      type: string;
      id: string;
      academyId?: string;
    },
    newContainerId?: string,
  ) => {
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, item.type)) {
      return `/admin/content/${item.type}/${item.id}`;
    }

    switch (item.type) {
      case AssetPickerTypes.ACADEMY_EVENT:
        return `/admin/academies/${item.academyId}/events/${item.id}`;
      case AssetPickerTypes.CHALLENGE:
        return `/admin/challenges/${item.id}`;
      case AssetPickerTypes.CLASS:
        return `/admin/classes/${item.id}`;
      case AssetPickerTypes.GUIDE:
        return `/admin/guides/${item.id}`;
      case AssetPickerTypes.GUIDE_SECTION:
      case AssetPickerTypes.GUIDE_TASK:
      case AssetPickerTypes.SECTION:
      case AssetPickerTypes.TASK:
        return AssetPickerTypes.getItemAdminEditUrl(item, newContainerId);
      case AssetPickerTypes.RESOURCE:
        return `/admin/resources/${item.id}`;
      case AssetPickerTypes.SERIES:
        return `/admin/paths/${item.id}`;
      case AssetPickerTypes.UNIVERSITY:
        return `/admin/universities/${item.id}`;
      case AssetPickerTypes.WORKFLOW:
        return `/admin/training_workflows/${item.id}`;
      default:
        datadogLogs.logger.info(`Cannot get admin item url for ${item.type}`);
        return null;
    }
  },

  /** Admin editing page. May be null if item has no separate editing page. */
  getItemAdminEditUrl: (item, newContainerId) => {
    if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, item.type)) {
      return `/admin/content/${item.type}/${item.id}/edit`;
    }

    switch (item.type) {
      case AssetPickerTypes.CHALLENGE:
        return `/admin/challenges/${item.id}/edit`;
      case AssetPickerTypes.CLASS:
        return `/admin/classes/${item.id}/edit`;
      case AssetPickerTypes.GUIDE:
        return `/admin/guides/${item.id}/edit`;
      case AssetPickerTypes.GUIDE_SECTION:
      case AssetPickerTypes.SECTION:
        return `/admin/guides/${newContainerId}/edit`;
      case AssetPickerTypes.GUIDE_TASK:
      case AssetPickerTypes.TASK:
        return `/admin/tasks/${item.id}/edit`;
      case AssetPickerTypes.SERIES:
        return `/admin/paths/${item.id}/edit`;
      case AssetPickerTypes.WORKFLOW:
        return `/admin/training_workflows/${item.id}/edit`;
      default:
        datadogLogs.logger.info(`Cannot get admin item edit url for ${item.type}`);
        return null;
    }
  },

  /** Admin submission pages. May be null. */
  getItemSubmissionUrl: (item) => {
    switch (item.type) {
      case AssetPickerTypes.CHALLENGE:
        return `/admin/challenge_user_assignments/${item.id}/review`;
      case AssetPickerTypes.GUIDE:
        return `/admin/guide_assignments/${item.id}/submissions`;
      default:
        datadogLogs.logger.info(`Cannot get admin item submission url for ${item.type}`);
        return null;
    }
  },

  getManagerReviewUrl: (item) => {
    switch (item.type) {
      case AssetPickerTypes.CHALLENGE:
        return `/admin/challenge_user_assignments/${item.id}/review`;
      case AssetPickerTypes.GUIDE:
        return `/admin/guide_assignments/${item.id}/submissions`;
      case AssetPickerTypes.RESOURCE:
        return `/admin/resource_assignments/${item.id}/review`;
      default:
        datadogLogs.logger.info(`Cannot get manager review url for ${item.type}`);
        return null;
    }
  },

  getChallengeReviewerUrl: (item, challengeReviewerAssignmentId) => {
    switch (item.type) {
      case AssetPickerTypes.CHALLENGE:
        return `/challenge_reviewer_assignments/${challengeReviewerAssignmentId}`;
      default:
        datadogLogs.logger.info(`Cannot get challenge reviewer url for ${item.type}`);
        return null;
    }
  },

  getDisplayName: (type, pluralize = false) => {
    switch (type) {
      case AssetPickerTypes.ACADEMY_CERTIFICATION:
        return I18n.t('common.academy.content.certification');
      case AssetPickerTypes.ACADEMY_PATH:
        return I18n.t('common.academy.content.path');
      case AssetPickerTypes.ACADEMY_EVENT:
        return I18n.t('common.academy.content.event');
      case AssetPickerTypes.CHALLENGE:
        return I18n.t('common.content.challenge', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.CLASS:
        return I18n.t('common.content.event', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.EVENT_TEMPLATE:
        return I18n.t('common.content.event_template', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.FOLDER:
        return I18n.t('common.content.folder', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GO1_CONTENT:
        return I18n.t('common.content.go1_content', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE:
        return I18n.t('common.content.guide', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE_SECTION:
        return I18n.t('common.content.guide_section', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE_TASK:
        return I18n.t('common.content.guide_task', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.LINKEDIN_LEARNING:
        return I18n.t('common.content.linkedin_learning', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.RESOURCE:
        return I18n.t('common.content.resource', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SCORM:
        return I18n.t('common.content.scorm', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SECTION:
        return I18n.t('common.content.section', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SERIES:
        return I18n.t('common.content.path', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.TASK:
        return I18n.t('common.content.task', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.UDEMY:
        return I18n.t('common.content.udemy', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.UNIVERSITY:
        return I18n.t('common.content.university', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.WORKFLOW:
        return I18n.t('common.content.workflow', { count: pluralize ? 2 : 1 });
      default:
        datadogLogs.logger.info(`Cannot get display name for ${type}`);
        return null;
    }
  },

  getAcademiesDisplayName: (type, pluralize = false) => {
    switch (type) {
      case AssetPickerTypes.ACADEMY_CERTIFICATION:
        return I18n.t('common.academy.content.certification');
      case AssetPickerTypes.ACADEMY_COLLECTION:
        return I18n.t('common.academy.content.collection');
      case AssetPickerTypes.ACADEMY_PATH:
        return I18n.t('common.academy.content.path');
      case AssetPickerTypes.ACADEMY_EVENT:
        return I18n.t('common.academy.content.event');
      case NonAssetPickerTypes.ACADEMY_EVENT_SESSION:
        return I18n.t('common.academy.content.session');
      case NonAssetPickerTypes.ACADEMY_EVENT_ON_DEMAND_CONTENT:
        return I18n.t('common.academy.content.on_demand_content');
      case AssetPickerTypes.CHALLENGE:
        return I18n.t('common.content.challenge', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.CLASS:
        return I18n.t('common.content.event', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.FOLDER:
        return I18n.t('common.content.folder', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE:
        return I18n.t('common.content.course', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE_SECTION:
        return I18n.t('common.content.guide_section', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.GUIDE_TASK:
        return I18n.t('common.content.guide_task', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.RESOURCE:
        return I18n.t('common.content.resource', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SCORM:
        return I18n.t('common.content.course', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SECTION:
        return I18n.t('common.content.section', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.SERIES:
        return I18n.t('common.content.path', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.TASK:
        return I18n.t('common.content.task', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.UNIVERSITY:
        return I18n.t('common.content.university', { count: pluralize ? 2 : 1 });
      case AssetPickerTypes.WORKFLOW:
        return I18n.t('common.content.workflow', { count: pluralize ? 2 : 1 });
      default:
        datadogLogs.logger.info(`Cannot get display name for ${type}`);
        return null;
    }
  },

  getAcademiesIconSrc: (type) => {
    switch (type) {
      case AssetPickerTypes.ACADEMY_CERTIFICATION:
        return '/images/academy/certification_icon.svg';
      case AssetPickerTypes.GUIDE:
        return '/images/academy/course_icon.svg';
      case AssetPickerTypes.RESOURCE:
        return '/images/academy/resource_icon.svg';
      case AssetPickerTypes.SCORM:
        return '/images/academy/scorm_icon.svg';
      default:
        datadogLogs.logger.info(`Cannot get academy icon source for ${type}`);
        return null;
    }
  },
};

export const NonAssetPickerTypes = {
  ...APP_CONSTANTS.NON_ASSET_PICKER_TYPES,
};

export default AssetPickerTypes;
