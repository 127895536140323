import { APP_CONSTANTS } from '~/common/explicit-exports';

export const {
  EVENT_VIEWED,
  EVENT_SESSION_VIEWED,
  SEARCH_RESULTS_CLICKED,
  SEARCH,
  CONTENT_ADDED_TO_CART,
  DOWNLOADS_CERTIFICATION,
  COPIES_CERTIFICATION_LINK,
  ADDS_CERTIFICATION_LINKEDIN,
  EXITS_COURSE,
  TASK_VIEWED,
  CONTENT_COMPLETED,
  TASK_COMPLETED,
  HIDES_CONTENT,
  VIEWS_CONTENT,
  ENTERS_TRAINING_PATH,
  ENTERS_TRAINING_CERTIFICATION,
  PATH_CHECKED_OUT,
  CERTIFICATION_CHECKED_OUT,
} = APP_CONSTANTS.ACADEMY_SEGMENT_EVENTS;
